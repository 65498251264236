import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import ContactComponent from "../../components/contact/contact";

const Contact = () => {

  return (
    <>
      <Helmet>
        <body className=""/>
      </Helmet>
      <Layout>
        <SEO title="Contact" description="Une question ? Un devis ? Une réclamation ? Déposez votre demande directement via notre site internet et recevez la réponse de l'un de nos experts en assurance dans les plus brefs délais."/>

        <ContactComponent isShowContact={true} setShowContact={()=>{}} returnToHome={true} />

      </Layout>
    </>
  );
};

export default Contact;